import { captureMessage, Scope, SeverityLevel } from "@sentry/react";
import { isArray, isEmpty, isPlainObject, isString, isUndefined } from "lodash-es";
import { ReactNode } from "react";

import { isProduction } from "utils/environments";
import ApiError from "utils/errors/ApiError";
import { dotNationToValue, objectToDotNation, recursiveSearch } from "utils/objects";

import { ENotificationType } from "ui/Notification/types";

import useNotification from "./useNotification";
import useTranslation from "./useTranslation";

export type TErrorTypes = string[] | ApiError | Error | string | unknown;
type TLevel = ENotificationType;

type TResultError = string | string[];

interface IParseErrors {
	error: TResultError;
	overridden?: string;
	code: EErrorCodes;
}

interface IConfig {
	level?: TLevel;
	statusOverrideMessages?: { [status: number]: string | false };
	fieldsPrefix?: string;
}

enum EErrorCodes {
	UNKNOWN = "UNKNOWN",
	UNHANDLED = "UNHANDLED",
	SCHEMA = "SCHEMA",
	VALIDATION = "VALIDATION",
	BY_CODE = "BY_CODE",
	ELSE = "ELSE",
	MESSAGE_ELSE = "MESSAGE_ELSE",
	INITIAL = "INITIAL",
	API_ERROR = "API_ERROR",
	OTHERS = "OTHERS",
}

enum ETranslations {
	UNDEFINED_MESSAGE = "NOTIFICATION.UNDEFINED_ERROR",
	ERROR_FIELD_KEY = "ERRORS.API.FIELD.{error}",
	ERROR_API_KEY = "ERRORS.API.{error}",
	FIELD_ERRORS = "ERRORS.API.FIELD_ERRORS",
	LIST_ERRORS = "ERRORS.API.LIST_ERRORS",
	STRING_ERROR = "ERRORS.API.STRING_ERROR",
}

const getExtErrorCode = (prefix: EErrorCodes, error?: TErrorTypes) => {
	if (isUndefined(error)) {
		return (prefix + "::UNDEFINED") as EErrorCodes;
	}

	if (isString(error)) {
		return (prefix + "::STRING") as EErrorCodes;
	}

	if (isArray(error)) {
		return (prefix + "::ARRAY") as EErrorCodes;
	}

	return (prefix + "::UNKNOWN") as EErrorCodes;
};

const useErrors = () => {
	const { t, withValuesAsString, withValues, withTaggedKey, tagKey } = useTranslation();

	const { add } = useNotification();

	let _level: TLevel = "error";
	let _fieldKey: string | ETranslations = ETranslations.ERROR_FIELD_KEY;

	const notify = (errors: unknown, result: IParseErrors) => {
		const scope = new Scope();

		const pureError = result?.overridden
			? result.overridden
			: isString(result.error)
			? result.error
			: result.error.join(", ");

		const sentryContext = {
			handled: errors,
			code: result?.code,
		};

		const level: SeverityLevel = "warning";

		scope.setContext("notification", sentryContext);
		scope.setLevel(level);
		scope.setTag("app_version", process.env.REACT_APP_CURRENT_RELEASE_VERSION || "No proper ENV variable!");

		captureMessage(pureError, scope);

		if (!isProduction) {
			console.warn({
				...sentryContext,
				pureError,
			});
		}
	};

	const isTranslatedApiError = (errorName: string) => {
		const translationKey = tagKey(ETranslations.ERROR_API_KEY, { error: errorName });

		const translated = withValuesAsString(translationKey, { code: errorName });

		return translated !== translationKey ? translated : false;
	};

	const tryTranslate = (errorName: string) => {
		const isTranslated = isTranslatedApiError(errorName);

		return isTranslated ? checkAndPrepare(isTranslated, EErrorCodes.BY_CODE) : undefined;
	};

	const handleAndNotify = (errors?: unknown, config?: IConfig) => {
		_level = config?.level || _level;
		// field must contain tag {error}
		_fieldKey = config?.fieldsPrefix || _fieldKey;

		const result = parseErrors(errors);

		if (errors instanceof ApiError) {
			const possibleMessage = config?.statusOverrideMessages?.[errors.getStatus()];

			if (!isUndefined(possibleMessage)) {
				if (isString(possibleMessage)) {
					// @todo:fix - overriding messages should have the same format as the others (with message prefix)
					add(possibleMessage || t(ETranslations.UNDEFINED_MESSAGE), _level);

					result.overridden = possibleMessage;

					notify(errors, result);
				}

				return;
			}
		}

		if (isArray(result.error)) {
			result.error.forEach(error => {
				add(error, _level);
			});
		} else {
			add(result.error, _level);
		}

		notify(errors, result);
	};

	const silent = (errors: unknown) => {
		const result = parseErrors(errors);

		notify(errors, result);
	};

	const checkAndPrepare = (
		error?: TErrorTypes,
		code?: EErrorCodes,
		config?: { noTranslateArrays?: boolean; withThrow?: boolean },
		errorCode?: string,
	): IParseErrors => {
		const localCode = code || EErrorCodes.UNKNOWN;

		const errorCodeToShow = !!errorCode ? ` [${errorCode}]` : "";

		if (isUndefined(error)) {
			return {
				error: t(ETranslations.UNDEFINED_MESSAGE) + errorCodeToShow,
				code: localCode,
			};
		}

		if (isString(error)) {
			return {
				error:
					withValuesAsString(ETranslations.STRING_ERROR, {
						error: t(error),
					}) + errorCodeToShow,
				code: localCode,
			};
		}

		if (isArray(error)) {
			return {
				error: config?.noTranslateArrays
					? !!errorCode
						? [...error, `[${errorCode}]`]
						: error
					: withValuesAsString(ETranslations.LIST_ERRORS, {
							errors: error.map(errorMessage => isTranslatedApiError(errorMessage) || t(errorMessage)).join(", "),
					  }) + errorCodeToShow,
				code: localCode,
			};
		}

		if (config?.withThrow) {
			throw new Error();
		}

		return {
			error: t(ETranslations.UNDEFINED_MESSAGE) + errorCodeToShow,
			code: EErrorCodes.UNHANDLED,
		};
	};

	const parseErrors = (error?: TErrorTypes): IParseErrors => {
		try {
			// try: string, array, undefined
			return checkAndPrepare(error, getExtErrorCode(EErrorCodes.INITIAL, error), { withThrow: true });
		} catch {
			if (error instanceof ApiError) {
				const apiErrors = error.getError();

				const errorCodeValue = apiErrors?.error_code;

				try {
					// try: string, array, undefined
					return checkAndPrepare(
						apiErrors,
						getExtErrorCode(EErrorCodes.API_ERROR, apiErrors),
						{ withThrow: true },
						errorCodeValue,
					);
				} catch {
					// error with message field
					if (apiErrors.hasOwnProperty("message")) {
						const messageValueError = apiErrors.message;

						// _schema errors
						const trySchema = recursiveSearch(messageValueError, "_schema");

						if (trySchema.length > 0) {
							const arrayOfErrors: string[] = [];

							trySchema.forEach(schemaErrors => {
								const errorsList = schemaErrors?.map(schemaErrorItem => {
									const translationKey = tagKey(ETranslations.ERROR_API_KEY, { error: schemaErrorItem });

									return t(translationKey) === translationKey ? schemaErrorItem : t(translationKey);
								});

								arrayOfErrors.push(
									withValuesAsString(ETranslations.STRING_ERROR, {
										error: errorsList.join(", "),
									}),
								);
							});

							return checkAndPrepare(
								arrayOfErrors,
								EErrorCodes.SCHEMA,
								{
									noTranslateArrays: true,
								},
								errorCodeValue,
							);
						}

						// validation errors
						if (isPlainObject(messageValueError)) {
							const arrayOfErrors: ReactNode[] = [];

							objectToDotNation(messageValueError).forEach(p => {
								const path = p.toString().toUpperCase();

								const pathTranslationKey = tagKey(_fieldKey, { error: path });

								const errorsList = dotNationToValue(messageValueError, p).map(err =>
									withTaggedKey(ETranslations.ERROR_API_KEY, { error: err }),
								);

								arrayOfErrors.push(
									withValues(
										ETranslations.FIELD_ERRORS,
										{
											field_name: t(pathTranslationKey) === pathTranslationKey ? p : t(pathTranslationKey),
											errors: errorsList?.length > 0 ? ": " + errorsList.join(", ") : "",
										},
										null,
									),
								);
							});

							return checkAndPrepare(
								arrayOfErrors,
								EErrorCodes.VALIDATION,
								{
									noTranslateArrays: true,
								},
								errorCodeValue,
							);
						}

						// with error code (like cognito)
						if (!isEmpty(apiErrors.name) && !["ApiError", "Error"].includes(apiErrors.name)) {
							const translateTry = tryTranslate(apiErrors.name);

							if (translateTry) {
								return translateTry;
							}
						}

						// try: handle rest of .message
						return checkAndPrepare(messageValueError, EErrorCodes.MESSAGE_ELSE, undefined, errorCodeValue);
					}
				}
			}

			// standard Error
			if (error instanceof Error) {
				if (!["Error"].includes(error.name)) {
					const translateTry = tryTranslate(error.name);

					if (translateTry) {
						return translateTry;
					}
				}

				if (isString(error.message)) {
					return checkAndPrepare(error.message, EErrorCodes.OTHERS);
				}
			}

			// try: rest of errors
			return checkAndPrepare(t(ETranslations.UNDEFINED_MESSAGE), EErrorCodes.ELSE);
		}
	};

	const tryAndHandle = (
		tryFunction?: () => void,
		finallyFunction?: () => void,
		errorResponse = false,
		level?: TLevel,
	) => {
		try {
			if (typeof tryFunction === "function") {
				return tryFunction();
			}
		} catch (error) {
			handleAndNotify(error, { level });

			return errorResponse;
		} finally {
			if (typeof finallyFunction === "function") {
				finallyFunction();
			}
		}
	};

	const getStatusCodeAndMessage = (errors?: unknown): { code: number; message: TResultError } | undefined => {
		if (errors instanceof ApiError) {
			return {
				code: errors.getStatus(),
				message: errors.getError().message,
			};
		}
		return undefined;
	};

	return { handleAndNotify, tryAndHandle, silent, parseErrors, getStatusCodeAndMessage };
};

export default useErrors;
