import { IId } from "data/types";
import { EUserOnlineStatus } from "data/users/types";

import colors from "styles/colors";

export const excludedIIdFields: (keyof IId)[] = ["id", "modified_by", "created_by", "modified_on", "created_on"];

export const sessionExpiredQueryParam = "sessionExpired";
export const newPasswordRequiredQueryParam = "newPasswordRequired";

export const marketingIDParam = "mid";
export const accountTypeParam = "accountType";
export const languageParam = "lang";

export const localStorageKeys = {
	user: {
		hasEverLogged: { key: "__LOCALBINI__.user.hasEverLogged", defaultValue: "true" },
	},
	notifications: {
		showOnlyUnread: { key: "__LOCALBINI__.user.notifications.showOnlyUnread", defaultValue: "true" },
	},
	languages: {
		selectedLanguage: {
			key: "__LOCALBINI__.languages.selectedLanguage",
			defaultValue: "en",
		},
	},
};

export const promoCodeForMarketing = "LBMARKETINGONLYINTERNALUSE";

export const maxLimitOfItemsForAPICalls = 100;

export const cookieNameForCookiesPopup = "localbiniCookiesAgreement";

export const localbiniProductionDomainLink = "https://localbini.com";

export const USER_ONLINE_STATUS_COLOR = {
	[EUserOnlineStatus.ACTIVE]: colors.accentGreen500,
	[EUserOnlineStatus.MID_ACTIVE]: colors.accentGreen200,
	[EUserOnlineStatus.LESS_ACTIVE]: colors.accentYellow200,
	[EUserOnlineStatus.NO_ACTIVE]: colors.accentRed500,
	[EUserOnlineStatus.UNDEFINED]: colors.accentGrey300,
};

export const ERROR_STATUS_CODES = {
	superbooking: {
		overruleConstrains: 409,
	},
};
