import { TDate } from "data/dates/types";
import { EExpType } from "data/experiences/types";
import { ECurrency } from "data/financials/types";
import { ERejectionReasonType } from "data/notifications/types";
import { IId } from "data/types";
import { ILocalhostDetails } from "data/users/types";

export enum EBookingStatus {
	NEW = "NEW",
	PAID = "PAID",
	PAYMENT_FAILED = "PAYMENT_FAILED",
	CANCELED = "CANCELED",
	REFUNDED = "REFUNDED",
	PERFORMED = "PERFORMED",
	PERFORMED__REFUNDED = "PERFORMED__REFUNDED",
	ABANDONED = "ABANDONED",
	EXPIRED = "EXPIRED",
}

export enum EStatusSuperBooking {
	NEW = "NEW",
	AWAITING_HOST = "AWAITING_HOST",
	NOTIFIED = "NOTIFIED",
	HOST_CONFIRMED = "HOST_CONFIRMED",
	PERFORMED = "PERFORMED",
	CANCELED = "CANCELED",
	ABANDONED = "ABANDONED",
	EXPIRED = "EXPIRED",
}

export enum EStatusHostingRequest {
	SENT = "SENT",
	ACCEPTED = "ACCEPTED",
	REJECTED = "REJECTED",
	TIMED_OUT = "TIMED_OUT",
	CANCELED = "CANCELED",
}

export enum EHostingRequestContactStatus {
	INTERESTED = "INTERESTED",
	NOT_INTERESTED = "NOT_INTERESTED",
	NO_TIME = "NO_TIME",
	TOO_LOW_EARNINGS = "TOO_LOW_EARNINGS",
	NOT_PICKED_UP = "NOT_PICKED_UP",
	MOVED_OUT = "MOVED_OUT",
	PLATFORM_ISSUES = "PLATFORM_ISSUES",
	WRONG_NUMBER = "WRONG_NUMBER",
	NO_CONTACT = "NO_CONTACT",
}

export enum EDeliveryMethod {
	EMAIL = "EMAIL",
	PUSH = "PUSH",
	SMS = "SMS",
	SMS_AND_EMAIL = "SMS_AND_EMAIL",
}

export enum ECancellationReason {
	CANCELED_BY_LOCALHOST = "CANCELED_BY_LOCALHOST",
	CANCELED_BY_TRAVELER = "CANCELED_BY_TRAVELER",
	LOCALHOST_NOT_FOUND = "LOCALHOST_NOT_FOUND",
	BOOKING_AMENDED = "BOOKING_AMENDED",
}

enum ESubdomain {
	PERCENTAGE = "PERCENTAGE",
	FIXED_AMOUNT = "FIXED_AMOUNT",
}

export enum EBookingSource {
	BO_DUPLICATION = "BO_DUPLICATION",
	BO_FOR_MARKETING = "BO_FOR_MARKETING",
	BO_FOR_TRAVELER = "BO_FOR_TRAVELER",
	CONCIERGE = "CONCIERGE",
	MOBILE_APP = "MOBILE_APP",
	PUBLIC_API = "PUBLIC_API",
	SUBDOMAIN = "SUBDOMAIN",
	WEB_APP = "WEB_APP",
	UNDEFINED = "UNDEFINED",
}

export enum ESuperbookingQAStatus {
	COMPLETED = "COMPLETED",
	POST_CHECK = "POST_CHECK",
	PRE_CHECK = "PRE_CHECK",
	TOO_EARLY = "TOO_EARLY",
	WAITING_FOR_BOOKING_PERFORMED = "WAITING_FOR_BOOKING_PERFORMED",
	CANCELED = "CANCELED",
}

export enum ELocalhostPreStatus {
	TOO_EARLY = "TOO_EARLY",
	AUTOMATIC_CONTACT = "AUTOMATIC_CONTACT",
	CONTACT_NOT_NEEDED = "CONTACT_NOT_NEEDED",
	CONTACT_TO_CONSIDER = "CONTACT_TO_CONSIDER",
	CONTACT_NEEDED = "CONTACT_NEEDED",
	CONTACT_URGENTLY_NEEDED = "CONTACT_URGENTLY_NEEDED",
	CANCELED = "CANCELED",
	EVERYTHING_OK = "EVERYTHING_OK",
	NOT_PICKED_UP = "NOT_PICKED_UP",
	WRONG_NUMBER = "WRONG_NUMBER",
	NEED_HELP = "NEED_HELP",
	NEED_TO_REPLACE_LOCAL = "NEED_TO_REPLACE_LOCAL",
	NO_CONTACT_WITH_LOCALHOST = "NO_CONTACT_WITH_LOCALHOST",
	NO_CONTACT_WITH_TRAVELER = "NO_CONTACT_WITH_TRAVELER",
}

export enum ELocalhostPostStatus {
	TOO_EARLY = "TOO_EARLY",
	AUTOMATIC_CONTACT = "AUTOMATIC_CONTACT",
	CONTACT_NOT_NEEDED = "CONTACT_NOT_NEEDED",
	CONTACT_TO_CONSIDER = "CONTACT_TO_CONSIDER",
	CONTACT_NEEDED = "CONTACT_NEEDED",
	CONTACT_URGENTLY_NEEDED = "CONTACT_URGENTLY_NEEDED",
	CANCELED = "CANCELED",
	EVERYTHING_OK = "EVERYTHING_OK",
	NOT_PICKED_UP = "NOT_PICKED_UP",
	WRONG_NUMBER = "WRONG_NUMBER",
	NEED_TO_CALL_TRAVELER = "NEED_TO_CALL_TRAVELER",
	NO_CONTACT_WITH_LOCALHOST = "NO_CONTACT_WITH_LOCALHOST",
	NO_CONTACT_WITH_TRAVELER = "NO_CONTACT_WITH_TRAVELER",
}

export enum ETravellerPostStatus {
	TOO_EARLY = "TOO_EARLY",
	AUTOMATIC_CONTACT = "AUTOMATIC_CONTACT",
	CONTACT_NOT_NEEDED = "CONTACT_NOT_NEEDED",
	CONTACT_TO_CONSIDER = "CONTACT_TO_CONSIDER",
	CONTACT_NEEDED = "CONTACT_NEEDED",
	CONTACT_URGENTLY_NEEDED = "CONTACT_URGENTLY_NEEDED",
	CANCELED = "CANCELED",
	EVERYTHING_OK = "EVERYTHING_OK",
	NOT_PICKED_UP = "NOT_PICKED_UP",
	WRONG_NUMBER = "WRONG_NUMBER",
	NEED_TO_CALL_LOCALHOST = "NEED_TO_CALL_LOCALHOST",
	REFUND_REQUESTED = "REFUND_REQUESTED",
	NO_CONTACT_WITH_LOCALHOST = "NO_CONTACT_WITH_LOCALHOST",
	NO_CONTACT_WITH_TRAVELER = "NO_CONTACT_WITH_TRAVELER",
}

export enum ESuperbookingQASystemStatus {
	TOO_EARLY = "TOO_EARLY",
	AUTOMATIC_CONTACT = "AUTOMATIC_CONTACT",
	CONTACT_NOT_NEEDED = "CONTACT_NOT_NEEDED",
	CONTACT_TO_CONSIDER = "CONTACT_TO_CONSIDER",
	CONTACT_NEEDED = "CONTACT_NEEDED",
	CONTACT_URGENTLY_NEEDED = "CONTACT_URGENTLY_NEEDED",
	CANCELED = "CANCELED",
}

export interface ITravelerDetails {
	first_name: string;
	last_name: string | null;
	email: string | null;
	phone: string | null;
}

export interface IBooking {
	canceled_by?: string;
	canceled_on?: string;
	expires_on?: string;
	cancellation_reason?: ECancellationReason;
	city?: string;
	comments?: string | null;
	country?: string;
	currency?: ECurrency | "N/A";
	date_time: string;
	eid: string;
	exp_title?: string;
	exp_type?: EExpType;
	exp_version?: number;
	held_code?: string;
	language: string;
	localhost_details: ILocalhostDetails | null;
	localhost_id?: string | null;
	overrule_constraints?: boolean;
	paid_travelers?: number;
	partner_id: string | null;
	partner_int_bid: string | null;
	price?: number | null;
	private?: boolean;
	shared?: boolean;
	source: EBookingSource;
	status: EBookingStatus;
	subdomain_commission?: {
		type: ESubdomain;
		value: number;
	};
	superbooking_id?: string;
	superbooking_status?: EStatusSuperBooking;
	ticket_url?: string;
	tickets: {
		adults: number;
		kids?: {
			quantity: number;
			age: number;
		}[];
	};
	total_travelers?: number;
	traveler_details: ITravelerDetails | null;
	traveler_id?: string | null;
	utc_date_time: string;
	utc_offset?: number;
	exp_duration_minutes: number;
	refunded_amount?: number;
	// Only for STAFF user START
	payment_transaction_id?: string;
	refund_transaction_id?: string;
	partner_resell_price?: number | null;
	// Only for STAFF user END
}

export type IBookingIId = IBooking & IId;

export type ISuperbooking = Pick<
	IBooking,
	| "city"
	| "country"
	| "date_time"
	| "eid"
	| "exp_title"
	| "exp_type"
	| "exp_version"
	| "language"
	| "localhost_details"
	| "localhost_id"
	| "private"
	| "total_travelers"
	| "utc_date_time"
	| "utc_offset"
	| "exp_duration_minutes"
	| "paid_travelers"
	| "overrule_constraints"
> & { status: EStatusSuperBooking; total_groups?: number };

export type ISuperbookingIId = ISuperbooking & IId;

export type ITickets = { adults: number; kids?: { age: number; quantity: number }[] };

export interface IPoolUser {
	added_by: string;
	id: string;
	joined_on: string;
}

export interface IPool extends IId {
	hosts: IPoolUser[];
}

interface IEarningsValue {
	currency: ECurrency;
	value: number;
}

export interface IEarningsEstimateForUsers {
	[userId: string]: IEarningsValue;
}

export interface IPendingRequestLastContact {
	contact_status?: EHostingRequestContactStatus;
	contacted_by?: string;
	contacted_on?: string;
}

export interface IPendingRequest extends IId, IPendingRequestLastContact {
	earnings_details?: {
		currency: ECurrency;
		guest_dependant_system_value: { value: number; range_start: number }[];
		initial_system_value: number;
		proposed_value: number | null;
	} | null;
	localhost_id: string | null;
	localhost_details: ILocalhostDetails | null;
	localhost_earnings: IEarningsValue | null;
	responded_on: TDate | null;
	rejection_reason: ERejectionReasonType | null;
	rejection_comment: string | null;
	seen_on: TDate | null;
	sent_on: TDate;
	status: EStatusHostingRequest;
	delivery_method: EDeliveryMethod;
	superbooking_id: string;
	superbooking_details: {
		city: string;
		country: string;
		date_time: TDate;
		eid: string;
		exp_title: string;
		exp_type: EExpType;
		exp_version: number;
		language: string;
		utc_date_time: TDate;
		utc_offset: number;
		total_groups: number;
		total_travelers: number;
		exp_duration_minutes: number;
	};
}

export interface IPostReimburseLocalhostCosts {
	amount_per_traveler?: number;
	comment: string;
	created_at_time_of_booking?: boolean;
	total_amount?: number;
}

export interface ISuperbookingQADetails {
	contacted_by: string | null;
	contacted_on: string | null;
	note: string | null;
	system_reason: string | null;
}

export interface ISuperbookingQATravellerDetails extends ISuperbookingQADetails {
	status: ETravellerPostStatus;
	// rating?: { average: number; count: number } | null;
}

export interface ISuperbookingQA {
	status: ESuperbookingQAStatus;
	superbooking_id: string;
	superbooking: ISuperbookingIId;
	traveler_post_status: ETravellerPostStatus;
	traveler_post_details: Record<string, ISuperbookingQATravellerDetails>;
	localhost_pre_status: ELocalhostPreStatus;
	localhost_pre_details: ISuperbookingQADetails;
	localhost_post_status: ELocalhostPostStatus;
	localhost_post_details: ISuperbookingQADetails;
	localhost_id: string | null;
	localhost_details: (ILocalhostDetails & { ratings?: { average: number; count: number } | null }) | null;
}

export type TSuperbookingQAIId = ISuperbookingQA & Omit<IId, "id">;
